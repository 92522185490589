import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import InputWithIcon from "../components/common/From/InputWithIcon";
import PasswordInput from "../components/common/From/PasswordInput";
import Button from "../components/common/Button";
import { ImageUrl } from "../config/ImageUrl";
import { adminLogin } from "../Action/AdminAction";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { LoginSchema } from "../components/lib/schema";
import { BASE_NAME, BASEURL } from "../config/BaseUrl";
import md5 from 'md5'

const Login = () => {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("email", values?.email);
      formData.append("password",md5(values?.password) );
      const response = await adminLogin(formData);

      if (response?.data?.ResponseCode == 1 && response?.data?.Result == true) {
        toast.success(response?.data?.ResponseMsg);
        localStorage.setItem("token", response?.data?.data?.token);
        window.location = `${BASE_NAME}episode`;
        // navigate("/episode");
      } else {
        toast.error(response?.data?.ResponseMsg);
      }
    },
  });
  return (
    <>
      <div className="grid sm:grid-cols-2 grid-cols-1 justify-center sm:h-screen h-full items-center w-full min-h-screen bg-center	 bg-[url('../public/assets/images/MainBg.png')] bg-cover	">
        <div className=" flex flex-col items-center justify-center sm:py-0 py-10 space-y-10">
          <div className="overlay">
            <img
              className=" light-purple-filter"
              src={`${ImageUrl}assets/images/handPhone.png`}
              alt="hand-logo"
            />
          </div>
        </div>
        <div className="w-full flex flex-col items-center justify-center h-screen py-20 lg:pr-36 sm:pr-10 px-5">
          <div className="bg-[#100c0c] shadow-4xl w-full h-full rounded-2xl flex items-center justify-center">
            <form className="w-full" onSubmit={formik.handleSubmit}>
              <h2 className=" text-1xl font-semibold text-center text-white">
                Welcome Back
              </h2>
              <p className="text-xl font-normal  text-textColor text-center">
                Please Sign In to continue.
              </p>

              <div className="mt-8 flex flex-col gap-y-7 px-5 md:px-10 lg:px-10 2xl:px-20">
                <InputWithIcon
                  placeholder={"Email"}
                  label="Email"
                  type="email"
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.errors.email}
                  touched={formik.touched.email}
                  icon={
                    <img
                      src={`${ImageUrl}assets/icons/EmailIcon.svg`}
                      alt="email"
                    />
                  }
                />
                <PasswordInput
                  placeholder="Password"
                  name="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.errors.password}
                  touched={formik.touched.password}
                  icon={
                    <img
                      src={`${ImageUrl}assets/icons/lock.svg`}
                      alt="password"
                    />
                  }
                />
                <Link
                  to="/forget-password"
                  className=" text-lg font-normal text-white text-end"
                >
                  Forgot Password?
                </Link>
                <Button text="Sign In" className="w-full" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
